/* Inter font information https://rsms.me/inter/ */
@import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}
/*
  Adding the below to global.ts doesn't seem to work and is possibly
  an issue with Styled Components.
  See link: https://github.com/react-boilerplate/react-boilerplate/issues/2552
*/
@font-face {
  /* StarWriter font is specially made for Dr. Nell Duke
    and used with her permission. */
  font-family: "StarWriter";
  src: local("assets/fonts/StarWriter"), url("assets/fonts/StarWriter.otf") format("opentype");
}
