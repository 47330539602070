@keyframes bounce {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-8px); }
  100% { transform: translateY(0); }
}

.bounce {
  animation-name: bounce;
  animation-timing-function: linear;
}

@keyframes slideInDown {
  0% {
    transform: translateY(-150%);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

.slideInDown {
  animation: slideInDown 1000ms cubic-bezier(0, 0, 0.58, 1.0) forwards;
}

@keyframes slideOutUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-150%);
    opacity: 0;
  }
}

.slideOutUp {
  animation: slideOutUp 1000ms cubic-bezier(0.42, 0, 1.0, 1.0) forwards;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink 1500ms steps(1, end);
  animation-iteration-count: 2;
}
