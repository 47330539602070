html,
body {
  margin: 0;
}

.error-fallback-container {
  background: url('../assets/images/backgrounds/paper-background.png');
  background-position: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-fallback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 575px;
  padding: 32px 64px;

  background-color: #FFFFFF;
  border-radius: 54px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);

  text-align: center;
}

.error-fallback button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 106px;
  height: 106px;
  border: unset;
  border-radius: 50%;
  margin-bottom: 2em;

  background-color: #DEFBFF;
  box-shadow: 0px 10px 0px #147A88;
}

.error-fallback button img {
  width: 52px;
  height: 55px;
}

.error-fallback h1 {
  font-family: StarWriter;
  text-transform: lowercase;
  font-size: 96px;
  font-weight: normal;
  margin: 0 0 4px 0;
}

.error-fallback p {
  font-family: Inter;
  font-size: 20px;
  margin: 0 0 2em 0;
}

.error-fallback p.small {
  font-size: 14px;
  margin-top: 0;
  max-width: 250px;
}

.error-fallback a {
  color: #1F3E5A;
  font-weight: 600;
  letter-spacing: -0.01em;
  text-decoration-line: underline;
}